import Image from 'next/image';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

interface ScheduleCallModalProps {
  visible: boolean;
  setVisible: Function;
}

const ScheduleCallModal = ({ visible, setVisible }: ScheduleCallModalProps) => {
  useEffect(() => {
    loadMeetingsEmbedScript();
  }, []);

  const loadMeetingsEmbedScript = () => {
    const script = document.createElement('script');
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;
    document.body.appendChild(script);
  };

  return (
    <Modal
      show={visible}
      onHide={() => setVisible(false)}
      size='lg'
      centered
      className='modal-schedule-call'
    >
      <Modal.Body>
        <div className='modal-close' onClick={() => setVisible(false)}>
          <Image
            src='/assets/icons/close.png'
            width={40}
            height={40}
            priority={false}
            alt='Close'
            style={{ objectFit: 'contain' }}
            className='img-fluid'
          />
        </div>
        <div
          className='meetings-iframe-container'
          data-src='https://meetings.hubspot.com/rony-james?embed=true'
        ></div>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleCallModal;
